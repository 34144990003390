import axios from "axios";

//根据ID获取到申请售后详情
export function getAterGoodsInfoById(id) {
  if (id > 0) {
    return axios.get(`/w1/order/afterSales/info/${id}`);
  }
}

//获取到售后列表
export function GetAfterGoodsList(query) {
  return axios.get(`/w1/order/afterSales`, { params: query });
}

//提交售后商品
export function PostAfterGoods(postData) {
  return axios.post(`/w1/order/afterSales`, postData);
}

//获取在售订单列表
export function GetOrderList(query) {
  return axios.get(`/w1/order/saleOrder`, query);
}

//根据ID 获取订单商品
export function GetOrdeGoodsById(id) {
  return axios.get(`/w1/order/getGoods/${id}`);
}

//获取订单详情
export function GetOrderInfo(id) {
  return axios.get(`/w1/order/info/${id}`);
}
